<!-- 运营中心 运营配置 站点运营配置 双枪并充配置 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form>
        <div style="padding-top: 20px">
          <ykc-form ref="ruleForm" :model="model" :rules="rules">
            <ykc-form-item label="电站名称">
              {{ model.name }}
            </ykc-form-item>
            <ykc-form-item label="是否配置双枪并充" required>
              <ykc-radio
                :data="isMoreChargeList"
                v-model="model.isMoreCharge"
                @change="changeCharge"></ykc-radio>
            </ykc-form-item>
            <ykc-form-item v-if="model.isMoreCharge === 1" label="选择电桩" prop="pileIdList">
              <ykc-tree
                :data="treeData"
                :props="treeProps"
                ref="YkcTree"
                @check-change="handleTreeCheckChange"></ykc-tree>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { marketManage } from '@/service/apis';

  const initModel = () => ({
    name: '',
    stationId: '',
    isMoreCharge: 0,
    pileIdList: [],
  });

  export default {
    props: ['currentData', 'showDrawer'],
    watch: {
      showDrawer(val) {
        if (val) {
          this.handleOpen();
        }
      },
    },
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.model = initModel();
                  this.$emit('closeDrawer');
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        drawerTitle: '双枪并充配置',
        isMoreChargeList: [
          {
            id: 0,
            name: '否',
          },
          {
            id: 1,
            name: '是',
          },
        ],
        model: initModel(),
        rules: {
          pileIdList: [{ required: true, message: '请选择关联电桩', trigger: 'change' }],
        },
        treeData: [],
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        stationPileVOList: [], // 初始化 电桩列表
        isMoreChargePileIdList: [], // 初始化 已选中电桩
      };
    },
    methods: {
      changeCharge(val) {
        if (val === 1) {
          this.treeData = this.stationPileVOList;
          this.$nextTick(() => {
            this.handleTreeMounted();
          });
        } else {
          this.treeData = [];
          this.model.pileIdList = [];
        }
      },
      // 抽屉展示
      async handleOpen() {
        this.model = initModel();
        if (this.currentData) {
          Object.keys(this.model).forEach(key => {
            this.model[key] = this.currentData[key];
          });
        }
        this.getMenuTree();
      },
      // 菜单树初始化选中状态
      handleTreeMounted() {
        if (this.currentData) {
          const keys = this.isMoreChargePileIdList;
          console.log('handleTreeMounted', keys);
          this.model.pileIdList = [...keys];
          this.$refs.YkcTree.setCheckedKeys(keys, true);
        }
      },
      // 菜单树变化
      handleTreeCheckChange() {
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.pileIdList = keys;
      },
      // 菜单树初始化
      async getMenuTree() {
        this.treeData = [];
        this.model.pileIdList = [];
        this.stationPileVOList = [];
        this.isMoreChargePileIdList = [];
        marketManage
          .findStationAndPile({
            stationId: this.model.stationId,
          })
          .then(res => {
            this.treeData = res.stationPileVOList || [];
            this.stationPileVOList = res.stationPileVOList || [];
            this.isMoreChargePileIdList = res.isMoreChargePileIdList || [];
            if (this.model.isMoreCharge === 1) {
              this.$nextTick(() => {
                this.handleTreeMounted();
              });
            }
          });
      },
      // 保存
      beforeEnsure() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const reqParams = {
              ...this.model,
            };
            marketManage.updateStationIsMoreCharge(reqParams).then(() => {
              this.model = initModel();
              this.$emit('closeDrawer');
              this.$emit('finish');
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
