<!-- 运营中心 运营配置 站点运营配置 soc配置 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form>
        <div style="padding-top: 20px">
          <ykc-form ref="ruleForm" :model="model" :rules="rules">
            <ykc-form-item label="电站名称">
              {{ model.name }}
            </ykc-form-item>
            <ykc-form-item prop="soc" label="SOC上限">
              <ykc-input v-model="model.soc" placeholder="请输入SOC上限" maxlength="3">
                <template slot="append">%</template>
              </ykc-input>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { marketManage } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  const initModel = () => ({
    stationId: '',
    name: '',
    soc: '',
  });

  export default {
    props: ['currentData', 'showDrawer'],
    watch: {
      showDrawer(val) {
        if (val) {
          this.handleOpen();
        }
      },
    },
    data() {
      return {
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.model = initModel();
                  this.$emit('closeDrawer');
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.beforeEnsure();
            },
          },
        ],
        drawerTitle: 'SOC上限配置',
        model: initModel(),
        rules: {
          soc: [
            { required: true, message: '请输入soc上限', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.percentNumberType.regexp,
              trigger: 'blur',
              message: '请输入整数，soc上限范围(50%-100%)',
            },
            {
              validator: (rule, value, callback) => {
                if (Number(value) < 50 || Number(value) > 100) {
                  callback('请输入整数，soc上限范围(50%-100%)');
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            },
          ],
        },
      };
    },
    methods: {
      // 抽屉展示
      async handleOpen() {
        this.model = initModel();
        if (this.currentData) {
          Object.keys(this.model).forEach(key => {
            this.model[key] = this.currentData[key];
          });
        }
      },
      // 保存
      beforeEnsure() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const reqParams = {
              stationId: this.model.stationId,
              soc: this.model.soc,
            };
            marketManage.updateStationSoc(reqParams).then(() => {
              this.model = initModel();
              this.$emit('closeDrawer');
              this.$emit('finish');
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
